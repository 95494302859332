import React, {useCallback, useState} from 'react';
import {Banner, Button} from '@shopify/polaris';

const MigrateBannerForList = ({setActive}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleChange = useCallback((prevState) => setActive(!prevState), [setActive]);

  return (
    <div>
      {
        isOpen &&
        <Banner
          title="Migrate upsells from checkout.liquid to Checkout Extensions"
          status="info"
          onDismiss={() => {
            setIsOpen(false);
          }}
        >
          <p>
            If you upgraded your checkout to Checkout Extensibility, you can copy over your checkout upsells with this
            migration wizard. <Button   onClick={() => handleChange()} variant="monochromePlain">Click here to open the migration
            wizard</Button>
          </p>

        </Banner>
      }
    </div>
  );
};

export default MigrateBannerForList;